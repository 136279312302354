// These are Redux actions. For more information: https://redux.js.org/basics/actions

export const UPDATE_WEATHER = 'weather:updateWeather';
export const SHOW_ERROR = 'weather:showError';

export function updateWeather(newWeather) {
    return {
        type: UPDATE_WEATHER,
        payload: {
            weather: newWeather
        }
    }
}

export function showError() {
    return {
        type: SHOW_ERROR,
        payload: {
            weather: 'ERROR!'
        }
    }
}
