// These are Redux actions. For more information: https://redux.js.org/basics/actions

export const UPDATE_LESSON = 'lesson:updateLesson';
export const SHOW_ERROR = 'lesson:showError';

export function updateLesson(newLesson) {
    return {
        type: UPDATE_LESSON,
        payload: {
            lesson: newLesson
        }
    }
}

export function showError() {
    return {
        type: SHOW_ERROR,
        payload: {
            lesson: 'ERROR!'
        }
    }
}
