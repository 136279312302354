import React, { Suspense, lazy } from 'react';
import { Switch, Route, withRouter, Redirect, BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './App.css';
// import { slide as Menu } from 'react-burger-menu'
import { connect } from 'react-redux';
import { updateUser } from './actions/user-actions';
import { updateVideo } from './actions/video-actions';
import { updateLesson } from './actions/lesson-actions';
import { updateAlert } from './actions/alert-actions';
import { createSelector } from 'reselect';
import { isMobile } from "react-device-detect";


const PrivateRoute = lazy(() => import('./components/routes/privateRoute'));
const SignupMethod = lazy(() => import('./components/user/signupMethod'));
const SignupName = lazy(() => import('./components/user/signupName'));
const SignupPhone = lazy(() => import('./components/user/signupPhone'));
const SignupVerification = lazy(() => import('./components/user/signupVerification'));
const SignupOneLastThing = lazy(() => import('./components/user/signupOneLastThing'));
const CategoryChoosePage = lazy(() => import('./components/askUs/chooseCategoryPage'));
const ChooseTopicPage = lazy(() => import('./components/askUs/chooseTopicPage'));
const Home = lazy(() => import('./components/home/home'));
const LandingRoute = lazy(() => import('./components/routes/landingRoute'));
const PageNotFound = lazy(() => import('./components/general/pageNotFound'));
const ConnectionMessage = lazy(() => import('./components/header/connectionMessage'));
const MyProfilePage = lazy(() => import('./components/user/myProfilePage'));
const EditProfilePage = lazy(() => import('./components/user/editProfilePage'));
const ProfilePage = lazy(() => import('./components/user/profilePage'));
const progressPage = lazy(() => import('./components/progress/progressPage'));
const ChatPage = lazy(() => import('./components/community/chatPage'));
const OptOutPage = lazy(() => import('./components/general/optOut'));
const ContactUsPage = lazy(() => import('./components/contact/contactPage'));
const Onboarding1 = lazy(() => import('./components/onboarding/onboardingPage'));
const CommunityPage = lazy(() => import('./components/community/communityPage'));
const NewHeader = lazy(() => import('./components/header/newHeader'));
const DesktopHeader = lazy(() => import('./components/header/desktopHeader'));
const NewFooter = lazy(() => import('./components/footer/newFooter'));
const Tabs = lazy(() => import('./components/header/tabs'));
const TermsOfUse = lazy(() => import('./components/footer/terms'));
const PrivacyPage = lazy(() => import('./components/footer/privacyPage'));
const AlertManager = lazy(() => import('./components/header/alertManager'));

// const LandingPage = lazy(() => import('./components/general/landing/landing'));


class App extends React.Component {
  constructor(props) {
    super(props);
    
    this.onUpdateUser = this.onUpdateUser.bind(this);
    this.onUpdateVideo = this.onUpdateVideo.bind(this);
    this.onUpdateLesson = this.onUpdateLesson.bind(this);
    this.onUpdateAlert = this.onUpdateAlert.bind(this);
  }
  
  componentDidMount() {
    document.title = 'Agrifriend';
    this.onEntry();
  }

  onUpdateUser(event) {
    this.props.onUpdateUser(event.target.value);
  }

  onUpdateVideo(event) {
    this.props.onUpdateVideo(event.target.value);
  }

  onUpdateLesson(event) {
    this.props.onUpdateLesson(event.target.value);
  }

  onUpdateAlert(event) {
    this.props.onUpdateAlert(event.target.value);
  }

  onEntry() {
    fetch('/api/account/verify', {
      method: "GET",
      })
    .then(res => res.json())
    .then(json => {
        if (json.success) {
          this.props.onUpdateUser(json.userData);
        }
    })
    .catch(err => {console.log(err)});
  }

  render() {

    document.title = "Agrifriend";
    const pagesWithNoHeader = ["/", "/landing", "/landing.html", "/signup", "/login", "/signupPhone", "/signupVerification", "/signupOneLastThing", "/onboarding"];
    const pagesWithTabs = ["/community", "/contactPage", "/chooseCategory", "/home", "/chooseTopic", "/optout", "/privacy"];

    return (
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <ConnectionMessage />
          <AlertManager />
          <Route render={({location}) => !pagesWithNoHeader.includes(location.pathname) ? isMobile ? (<NewHeader />) : (<DesktopHeader />) : (<></>)} />
          <Route render={({location}) => pagesWithTabs.includes(location.pathname) ? isMobile ? (<Tabs />) : (<></>) : (<></>)} />
          <Switch> {/* This switch makes the Tabs (learn, community, etc...) disappear on selected routes */}
            {/* <Route exact path={["/login", "/signup"]} component={SignupMethod} /> */}
            {/* <Redirect exact from='/landing' to='/landing.html'/> */}
            {/* <LandingRoute path={["/", "/landing"]} exact={true} /> */}
            {/* <Route path={["/", "/landing"]} component={LandingPage} exact={true} /> */}
            <Route exact path={["/", "/landing"]} render={() => {window.location.href="landing/index.html"}} />
            <Route path="/signupName" component={SignupName} />
            <Route path={["/login", "/signup", "/signupPhone", "/loginPhone"]} component={SignupPhone} />
            <Route path="/signupVerification" component={SignupVerification} />
            <Route path="/signupOneLastThing" component={SignupOneLastThing} />
            <Route path="/onboarding" component={Onboarding1} />
            <PrivateRoute path="/profile" component={MyProfilePage} />
            <PrivateRoute path="/editProfilePage" component={EditProfilePage} />
            <PrivateRoute path="/profiles/:userId" component={ProfilePage} />
            <PrivateRoute path="/progress" component={progressPage} />
            <PrivateRoute path="/chat/:userId" component={ChatPage} />
            <Route path="/termsOfUse" component={TermsOfUse} />
            <PrivateRoute path="/community" component={CommunityPage} />
            <PrivateRoute path="/contactPage" component={ContactUsPage} />
            <PrivateRoute path="/optout" component={OptOutPage} />
            <PrivateRoute path="/chooseCategory" component={CategoryChoosePage} />
            <PrivateRoute path="/home" component={Home} />
            <PrivateRoute path="/chooseTopic" component={ChooseTopicPage} />
            {/* <Route path="/optout" component={Onboarding1} /> Todo: add opt-out page */}
            <Route path="/privacy" component={PrivacyPage} />
            <Route path="*" component={PageNotFound} />
          </Switch>
          <NewFooter />
        </Suspense>
      </Router>
    );
  }
}


const productsSelector = createSelector(
  state => state.products,
  products => products
);

const userSelector = createSelector(
  state => state.user,
  user => user
);

const videoSelector = createSelector(
  state => state.videos,
  video => video
);

const lessonSelector = createSelector(
  state => state.lesson,
  lesson => lesson
);

const alertSelector = createSelector(
  state => state.alert,
  alert => alert
);

const mapStateToProps = createSelector(
  productsSelector,
  userSelector,
  videoSelector,
  lessonSelector,
  alertSelector,
  (products, user, videos, lesson, alert) => ({
    products,
    user,
    videos,
    lesson,
    alert
  })
);

const mapActionsToProps = {
  onUpdateUser: updateUser,
  onUpdateVideo: updateVideo,
  onUpdateLesson: updateLesson,
  onUpdateAlert: updateAlert
};


export default withRouter(connect(mapStateToProps,
  mapActionsToProps
  )(App));
