import { UPDATE_LESSON, SHOW_ERROR } from '../actions/lesson-actions';

export default function lessonReducer(state = '', { type, payload}) {
    switch (type) {
        case UPDATE_LESSON:
            // return {
            //     ...state,
            //     lesson: payload.lesson
            // };
            return payload.lesson;
        case SHOW_ERROR:
            return payload.lesson;
        default:
            return state;
    }
}