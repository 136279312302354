import { UPDATE_WEATHER, SHOW_ERROR } from '../actions/weather-actions';

export default function weatherReducer(state = '', { type, payload}) {
    switch (type) {
        case UPDATE_WEATHER:
            return payload.weather;
        case SHOW_ERROR:
            return payload.weather;
        default:
            return state;
    }
}