import { UPDATE_VIDEO, SHOW_ERROR } from '../actions/video-actions';

export default function videosReducer(state = '', { type, payload}) {
    switch (type) {
        case UPDATE_VIDEO:
            return payload.video;
        case SHOW_ERROR:
            return payload.video;
        default:
            return state;
    }
}