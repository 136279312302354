import { UPDATE_USER, SHOW_ERROR } from '../actions/user-actions';

export default function userReducer(state = '', { type, payload}) {
    switch (type) {
        case UPDATE_USER:
            // console.log(payload);
            return payload.user;
            // return {
            //     ...state,
            //     user: payload.user
            // };
        case SHOW_ERROR:
            return payload.user;
        default:
            return state;
    }
}