// These are Redux actions. For more information: https://redux.js.org/basics/actions

export const UPDATE_ALERT = 'alert:updateAlert';
export const SHOW_ERROR = 'alert:showError';

export function updateAlert(newalert) {
    return {
        type: UPDATE_ALERT,
        payload: {
            alert: newalert
        }
    }
}

export function showError() {
    return {
        type: SHOW_ERROR,
        payload: {
            alert: 'ERROR!'
        }
    }
}
