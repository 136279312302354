// These are Redux actions. For more information: https://redux.js.org/basics/actions

export const UPDATE_VIDEO = 'videos:updateVideo';
export const SHOW_ERROR = 'videos:showError';

export function updateVideo(newVideo) {
    return {
        type: UPDATE_VIDEO,
        payload: {
            video: newVideo
        }
    }
}

export function showError() {
    return {
        type: SHOW_ERROR,
        payload: {
            video: 'ERROR!'
        }
    }
}
