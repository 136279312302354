import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App';
// import * as serviceWorker from './serviceWorker';
// import LocalServiceWorkerRegister from './sw-register';


import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
// import { composeWithDevTools } from "redux-devtools-extension";
import { Provider } from 'react-redux';
import productReducer from './reducers/product-reducer';
import userReducer from './reducers/user-reducer';
import videosReducer from './reducers/videos-reducer';
import lessonReducer from './reducers/lessons-reducer';
import weatherReducer from './reducers/weather-reducer';
import alertReducer from './reducers/alert-reducer';
import thunk from 'redux-thunk';

import { PersistGate } from 'redux-persist/integration/react'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web


// import TagManager from 'react-gtm-module'  // to be removed if proven irrelevant


const allReducers = combineReducers({
    products: productReducer,
    user: userReducer,
    videos: videosReducer,
    lesson: lessonReducer,
    weather: weatherReducer,
    alert: alertReducer
});

const allStoreEnhancers = compose(
    // composeWithDevTools(),  // Causing problems for people without the extansion!
    applyMiddleware(thunk),
);


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, allReducers)

let store = createStore(
    persistedReducer, 
    {
        user: {username: 'unknown'},
        videos: [{}],
        lesson: {},
        weather: {},
        alert: []
    },
    
    allStoreEnhancers
    );

let persistor = persistStore(store)

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <App />
            </Router>
        </PersistGate>
    </Provider>
    , document.getElementById('root')
);

// serviceWorker.register();
// LocalServiceWorkerRegister();
