import { UPDATE_ALERT, SHOW_ERROR } from '../actions/alert-actions';

export default function alertReducer(state = '', { type, payload}) {
    switch (type) {
        case UPDATE_ALERT:
            return payload.alert;
        case SHOW_ERROR:
            return payload.alert;
        default:
            return state;
    }
}